exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-account-jsx": () => import("./../../../src/pages/account/[...].jsx" /* webpackChunkName: "component---src-pages-account-jsx" */),
  "component---src-pages-bracket-challenge-jsx": () => import("./../../../src/pages/bracket-challenge/[...].jsx" /* webpackChunkName: "component---src-pages-bracket-challenge-jsx" */),
  "component---src-pages-guides-glossaries-faqs-jsx": () => import("./../../../src/pages/guides-glossaries-faqs.jsx" /* webpackChunkName: "component---src-pages-guides-glossaries-faqs-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-join-our-team-jsx": () => import("./../../../src/pages/join-our-team.jsx" /* webpackChunkName: "component---src-pages-join-our-team-jsx" */),
  "component---src-pages-subscribe-jsx": () => import("./../../../src/pages/subscribe.jsx" /* webpackChunkName: "component---src-pages-subscribe-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-bracket-challenge-bracket-jsx": () => import("./../../../src/templates/bracket-challenge/bracket.jsx" /* webpackChunkName: "component---src-templates-bracket-challenge-bracket-jsx" */),
  "component---src-templates-bracket-challenge-index-jsx": () => import("./../../../src/templates/bracket-challenge/index.jsx" /* webpackChunkName: "component---src-templates-bracket-challenge-index-jsx" */),
  "component---src-templates-bracket-challenge-leaderboard-jsx": () => import("./../../../src/templates/bracket-challenge/leaderboard.jsx" /* webpackChunkName: "component---src-templates-bracket-challenge-leaderboard-jsx" */),
  "component---src-templates-bracket-challenge-rules-jsx": () => import("./../../../src/templates/bracket-challenge/rules.jsx" /* webpackChunkName: "component---src-templates-bracket-challenge-rules-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-contest-index-jsx": () => import("./../../../src/templates/contest/index.jsx" /* webpackChunkName: "component---src-templates-contest-index-jsx" */),
  "component---src-templates-contest-rules-jsx": () => import("./../../../src/templates/contest/rules.jsx" /* webpackChunkName: "component---src-templates-contest-rules-jsx" */),
  "component---src-templates-job-jsx": () => import("./../../../src/templates/job.jsx" /* webpackChunkName: "component---src-templates-job-jsx" */),
  "component---src-templates-newsletter-jsx": () => import("./../../../src/templates/newsletter.jsx" /* webpackChunkName: "component---src-templates-newsletter-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-podcast-jsx": () => import("./../../../src/templates/podcast.jsx" /* webpackChunkName: "component---src-templates-podcast-jsx" */),
  "component---src-templates-podcasts-jsx": () => import("./../../../src/templates/podcasts.jsx" /* webpackChunkName: "component---src-templates-podcasts-jsx" */),
  "component---src-templates-puzzle-jsx": () => import("./../../../src/templates/puzzle.jsx" /* webpackChunkName: "component---src-templates-puzzle-jsx" */),
  "component---src-templates-quiz-jsx": () => import("./../../../src/templates/quiz.jsx" /* webpackChunkName: "component---src-templates-quiz-jsx" */),
  "component---src-templates-subscribe-vertical-jsx": () => import("./../../../src/templates/subscribe-vertical.jsx" /* webpackChunkName: "component---src-templates-subscribe-vertical-jsx" */)
}

